<template>
    <div style="width:21cm; direction:rtl;margin:-30px auto auto auto">

            <v-row class="p-2 m-2" style="width:21cm;margin:auto !important;">
                <b-button class="btn-success m-2" style="width:200px;" v-if="invoiced == false" id="printbtns1" @click="printPage()">طباعة  </b-button>
            </v-row>

            <div style="width:21cm;margin:auto" id="printmes" v-if="isCanceled == false" >
                <v-row class="text-center" style="border: 1px solid #ccc;background:#FFF">
                    <v-col cols="5" style="direction:rtl;padding-top:50px">
                        <div style="font-size:1.2em !important;">{{ settings.company_name }}</div>
                        <div style="font-size:1.2em !important;">جوال: {{settings.mobile}}</div>
                        <div style="font-size:1.2em !important;">{{settings.address}}</div>
                        <div style="font-size:1.2em !important;">الرقم الضريبي: {{settings.vat_number}}</div>
                    </v-col>
                    <v-col cols="3"></v-col>
                    <v-col cols="4" style="direction:ltr">
                        <img :src="`../api/`+card.qrurl">
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <v-col cols="4" style="font-size:1.2em !important;">
                        رقم الفاتورة: {{ this.cardid }}
                    </v-col>
                    <v-col cols="4" style="font-size:1.2em !important;">
                        فاتورة ضريبية / VAT Invoice
                    </v-col>
                    <v-col cols="4" style="font-size:1.2em !important;">
                        Date: {{ card.sdate.substr(0, 10) }}
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل<br>Customer Name</th>
                                <td style="background:#fff">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال<br>Mobile Number</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                               
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                     <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th colspan="6">تفاصيل الفاتورة</th>
                            </tr>
                            <tr>
                                <th style="width:35%;">الوصف<br>Discription</th>
                                <th>الكمية<br>Qty</th>
                                <th>السعر<br>Price Inc. VAT</th>
                                <th>الاجمالي<br>Total Ex. VAT</th>
                                <th>الضريبة<br>VAT</th>
                                <th>المجموع<br>Total Inc. VAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(serve,index) in serves" :key="index">
                                <td style="background:#fff">{{ serve.description }}</td>
                                <td style="background:#fff">{{ serve.qty }}</td>
                                <td style="background:#fff">{{ serve.price }}</td>
                                <td style="background:#fff">{{ serve.total }}</td>
                                <td style="background:#fff">{{ serve.vat }}</td>
                                <td style="background:#fff">{{ serve.ftotal }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                         
                                <td colspan="5" style="color:black;font-weight:bold;text-align:left;">المجموع بدون ضريبة</td>
                                <td style="color:black;font-weight:bold">{{ card.total }}</td>
                            </tr>
                            <tr>
                                
                                <td colspan="5" style="color:black;font-weight:bold;text-align:left;">الضريبة المضافة 15%</td>
                                <td  style="color:black;font-weight:bold">{{ card.vat }}</td>
                            </tr>
                            <tr>
                                
                                <td colspan="5" style="color:black;font-weight:bold;text-align:left;">الاجمالي مع الضريبة</td>
                                <td  style="color:black;font-weight:bold">{{ card.ftotal }}</td>
                            </tr>
                        </tfoot>
                    </b-table-simple>
                </v-row>

                <v-row class="text-center" style="margin-top:1cm;border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل</th>
                                <td style="background:#fff;width:30%;">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                                <th style="width:150px;">التوقيع</th>
                                <td style="background:#fff;width:30%;"></td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
            </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    
    data() {
        return {
            isCanceled: false,
            cardid: 0,
            card: {
                cardid: '',
                sdate: '',
                delivery: '',
                statusper: '',
                max: 100,
                clicked: [],
                total: 0,
                vat: 0,
                ftotal: 0
            },
            Customer: {
                name: '',
                mobile: '',
                email: '',
                city: '',
                Address: ' ',
            },
            car:{
                plate_number: '',
                car_madel_year: '',
                car_color: '',
                car_model: '',
            },
            settings:[

            ],
            parts:[],
            serves:[],
            totals:{
                pprice:0,
                ptotal:0,
                sprice:0,
                stotal:0,
                allprice:0,
                alltotal:0,
            },
            invoiceinfo: [],
            invoiced: false
        }
    },
    created() {

        this.cardid = this.$route.params.cardid;
        
        // this.getCard();
        this.getInvoice();
        this.getSettings(); 
        // this.getparts();
        // this.getServices();
        setTimeout(()=>{this.calcTotal();},1500);
        this.printPage();
       
    },
    methods: {
        createInv(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            this.$snotify.error('في حالة انشاء فاتورة فانك لن تستطيع أن تقوم بأي تعديل على الكرت', 'تنبيه', {
                timeout: 6000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: 'متأكد', 
                        action: (toast) => {
                            this.doCreate();
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            })
        },
        doCreate(){
            const formdata = new FormData();
            formdata.append('type','createInvoice');
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);

            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
            }); 
        },
        getInvoice(){
            const formdata = new FormData();
            formdata.append('type','getInvoice');
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);

            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                
                const res = response.data.results.data.results;
                
                this.card.sdate = res[0].inv_date;
                this.Customer.name = res[0].full_name;
                this.Customer.mobile = res[0].mobile;
                this.card.total = res[0].total;
                this.card.vat = res[0].vat;
                this.card.ftotal = res[0].ftotal;
                this.card.qrurl = res[0].qrurl;
                const ress = response.data.results.data.rows;
                let service = []
                for(let i = 0;i < ress.length;i++){
                    service.push({
                        description: ress[i].item_name,
                        qty: ress[i].qty,
                        total: ress[i].total,
                        ftotal: ress[i].ftotal,
                        vat: ress[i].vat,
                        price: ress[i].item_price,
                    })
                }
                this.serves = service;
                this.serve.description = 'dd';
            });
        },
        calcTotal(){
            const parts = this.parts;
            const serves = this.serves;
            
            for(let i=0;i<parts.length;i++){
                this.totals.ptotal = +this.totals.ptotal + +parts[i].total;
            }
            
            for(let k=0;k<serves.length;k++){
                this.totals.stotal = +this.totals.stotal + +serves[k].total;
            }

            this.totals.alltotal = +this.totals.ptotal + this.totals.stotal
            const t = this.$calcVat(this.totals.alltotal);
        },
        getparts(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','parts');
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.parts = res.results.data.results;
            });
            
        },
        getServices(){
            const post = new FormData();
            post.append('type','getCItem');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[type]','service');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((responses) => {
                const ress = responses.data;
                this.serves = ress.results.data.results;
            });
        },
        getCard(){
            this.cardid = this.$route.params.cardid;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type' , 'getCards');
            const auth = this.$cookies.get(this.$COOKIEPhase);
            post.append('auth',auth);
            post.append('data[cardid]',this.cardid)
            axios.post(
                '/e-car/public/index.php',
                post
            ).then((response) => {
                const res = response.data;
                this.Customer.name = res.results.data[0].customer;
                this.Customer.mobile = res.results.data[0].mobile;
                this.card.cardid = res.results.data[0].id;
                this.card.sdate = res.results.data[0].date_in;
                this.card.delivery = res.results.data[0].date_out;
                this.card.statusper = res.results.data[0].status;
                this.card.total = res.results.data[0].total;
                this.card.ftotal = res.results.data[0].ftotal;
                this.card.vat = res.results.data[0].vat;
                const cl = res.results.data[0].clicked;
                const clt = cl.split(',');
                for(let i = 0;i<clt.length;i++){ 
                this.card.clicked.push(parseInt(clt[i])); 
                }
                this.car.plate_number = res.results.data[0].plate_number;
                this.invoicedata = res.results.data[0];
                this.car.car_model = res.results.data[0].car_model;
                this.car.car_madel_year = res.results.data[0].car_madel_year;
                this.car.car_color = res.results.data[0].car_color;
                this.theclicked = this.card.clicked;
                this.getClicked("testing ",this.card.clicked);
            })
        },
        getClicked(clicked){
            this.card.clicked = clicked
        },
        printPage(){
            document.getElementById("Footer").style.display = "none";
            document.getElementById("printbtns1").style.display = "none";
            document.getElementsByTagName("header")[0].style.display = "none";
            window.print();
            window.close();
            document.getElementById("Footer").style.display = "block";
            document.getElementById("printbtns1").style.display = "block";
            document.getElementsByTagName("header")[0].style.display = "block";
        },
        getSettings(){
            const post = new FormData();
            post.append("type",'getSettings');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>
<style>
#printme *{
    direction:rtl;
    font-size:12px !important;
}
#printme{
    font-size:12px !important;
}
</style>